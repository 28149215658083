import { Link } from 'gatsby'
import styled from 'styled-components'

import { fluidFontSize, widthFrom } from '@theme'

export const CardCompany = styled(Link)`
	width: 100%;
	height: 100%;
	min-height: 200px;
	padding: 2em 2em 50px;
	padding: 2vw 2vw 50px;
	background: ${({ theme }) => theme.modify.rgba('ash', 0.7)};
	border-bottom: 2px solid ${({ theme }) => theme.color.secondary};
	overflow: hidden;
	text-decoration: none;
	position: relative;

	.logo {
		padding: 0 0 1em;
		${fluidFontSize(16, 24)}
		text-transform: uppercase;
		font-weight: ${({ theme }) => theme.fontWeight.semi};
		color: ${({ theme }) => theme.color.white};
	}

	.info {
		padding: 2em 0;
		border-top: 2px solid ${({ theme }) => theme.color.primary};
		color: ${({ theme }) => theme.color.primary};
		${fluidFontSize(14, 16)}
	}

	.viewInfoLink {
		content: '';
		position: absolute;
		right: 0;
		left: 0;
		bottom: 0;
		height: 50px;
		overflow: hidden;
		background-color: ${({ theme }) => theme.modify.rgba('secondary', 0.8)};
		z-index: 1000;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: all 0.2s ease-in;

		h2 {
			font-weight: normal;
			${({ min = 14, max = 18 }) => fluidFontSize(min, max)}
		}
	}

	&:hover {
		.viewInfoLink {
			height: 100%;
		}
	}

	/* Breakpoint */
	${widthFrom.TABLET`
			padding: 2em;
			padding: 2vw;

			.viewInfoLink {
				height: 0;

				h2 {
					${({ min = 18, max = 24 }) => fluidFontSize(min, max)}
				}
			}
  `}
`
