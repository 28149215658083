import React from 'react'

// Elements
import { Icon } from '@components'

// Styles
import { CardContact } from './ContactCard.styled'

const ContactCard = ({
	label,
	info,
	icon,
	gridColumn = 'auto / auto',
	gridRow = 'auto / auto',
	marginBot = 0,
	email = false,
	tel = false,
	mapLocation = false,
}) => {
	const multiRow =
		Array.isArray(info.props.children) && info.props.children.length > 2

	return (
		<CardContact
			gridColumn={gridColumn}
			gridRow={gridRow}
			multiRow={multiRow}
			marginBot={marginBot}
		>
			<p className="label">{label}</p>
			<div className="infoGroup">
				{email || tel ? (
					email ? (
						<a
							className="link"
							href={`mailto:${email}`}
							target="_blank"
							id="Contact_Email_Link"
						>
							<div className="info">{info}</div>
							<Icon icon={icon} />
						</a>
					) : (
						<a
							className="link"
							href={`tel:${tel}`}
							target="_blank"
							id="Contact_Tel_Link"
						>
							<div className="info">{info}</div>
							<Icon icon={icon} />
						</a>
					)
				) : mapLocation ? (
					<a
						className="link"
						href="https://goo.gl/maps/eSf2Po5mhecUnrEP6"
						target="_blank"
						id="Contact_Tel_Link"
					>
						<div className="info">{info}</div>
						<Icon icon={icon} />
					</a>
				) : (
					<>
						<div className="info">{info}</div>
						<Icon icon={icon} />
					</>
				)}
			</div>
		</CardContact>
	)
}

export default ContactCard
