import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

// Theme
import { GlobalStyle } from '@theme'

// Sections
import { SiteHeader, SiteFooter } from '@sections'

const Layout = ({ children, props, template }) => {
	const { path } = props

	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
		}
	`)

	return (
		<>
			<GlobalStyle />
			<SiteHeader
				path={path}
				siteTitle={data.site.siteMetadata.title}
				template={template}
			/>
			<main>{children}</main>
			<SiteFooter />
		</>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
