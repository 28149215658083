import React, { useState } from 'react'
import styled from 'styled-components'
import axios from 'axios'
import { Formik } from 'formik'
import * as Yup from 'yup'

// Theme
import { widthFrom, Title, Par } from '@theme'

// Elements
import { Icon } from '@components'
import { Input, Button } from '@components'

const FormContainer = styled.form`
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 1em;

	margin-top: 1.3em;

	.hidden {
		visibility: hidden;
		opacity: 0;
	}

	.FormTextArea {
		grid-column-end: -1;
		grid-row: span 2 / 7;
	}

	.FormBtn {
		grid-row-end: auto;
		grid-column-end: -1;
	}

	/* Breakpoint */
	${widthFrom.TABLET`
		grid-template-columns: 1fr 1fr;

		.FormTextArea {
		grid-column-end: -1;
		grid-row: span 2 / 4;
	}
  `}

	${widthFrom.DESKTOP`
		grid-template-columns: 1fr 1fr 1fr;

		.FormTextArea {
			grid-column-end: -1;
			grid-row: span 2 / 3; 
		}
  `}
`
const FormSentMsg = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-color: ${({ theme }) => theme.modify.rgba('secondary', 0.95)};
	z-index: 99999;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	padding: 5vh 5vw;

	.msg {
		width: 100%;
		text-align: center;
	}

	.msgIcon {
		width: 100px;
		padding: 0.3em;
		border-radius: 50%;
		background-color: ${({ theme }) => theme.color.chalk};
		margin-bottom: 1em;
		border: 0.2em solid ${({ theme }) => theme.modify.rgba('ele', 0.4)};

		.icon {
			transform: translate(20%, -10%);
		}
	}

	.closeBtn {
		position: absolute;
		top: 2em;
		right: 2em;
		width: 40px;
		height: 40px;
		cursor: pointer;

		svg {
			transition: all 300ms ease-in-out;
			fill: ${({ theme }) => theme.modify.rgba('white', 0.7)};
		}

		&:hover {
			svg {
				fill: ${({ theme }) => theme.modify.rgba('white', 1)};
			}
		}
	}

	/* Breakpoint */
	${widthFrom.TABLET`
		.msg {
			width: 60%;
		}
  `}
`

const FormValidationSchema = Yup.object().shape({
	name: Yup.string().required('Required'),
	tel: Yup.string().required('Required'),
	email: Yup.string().email('Invalid email').required('Requiresd'),
	message: Yup.string().required('Required'),
})

const Form = ({ formId, formSubject = 'Website form enquiry' }) => {
	const [showModal, setModalState] = useState(false)

	const hideModalHandler = () => {
		setTimeout(() => {
			setModalState(false)
		}, 20000)
	}

	const submitEmailerHandler = async (values, { resetForm }) => {
		const formValues = { subject: formSubject, ...values }
		try {
			const response = await axios.post(
				'/.netlify/functions/email',
				JSON.stringify(formValues)
			)
			resetForm({
				name: '',
				company: '',
				email: '',
				message: '',
				tel: '',
			})
			setModalState(true)
			hideModalHandler()
		} catch (error) {
			console.error({ type: 'error', text: error.response.data })
		}
	}

	return (
		<>
			<Formik
				initialValues={{
					name: '',
					company: '',
					email: '',
					message: '',
					tel: '',
				}}
				onSubmit={submitEmailerHandler}
				validationSchema={FormValidationSchema}
			>
				{(props) => {
					const {
						values,
						touched,
						errors,
						dirty,
						isValid,
						isSubmitting,
						handleChange,
						handleBlur,
						handleSubmit,
					} = props

					return (
						<FormContainer
							id={formId}
							onSubmit={handleSubmit}
							netlify-honeypot="bot-field"
							data-netlify="true"
						>
							<Input
								idFor="name"
								icon="busCard"
								label="Full Name"
								placeholder="Name Surname"
								type="text"
								value={values.name}
								onChangeHandler={handleChange}
								onBlurHandler={handleBlur}
								touched={touched.name}
								error={errors.name}
							/>
							<Input
								idFor="company"
								icon="building"
								label="Company Name"
								placeholder="Company"
								type="text"
								value={values.company}
								onChangeHandler={handleChange}
								onBlurHandler={handleBlur}
								touched={touched.company}
								error={errors.company}
							/>
							<Input
								customClassName="FormTextArea"
								as="textarea"
								idFor="message"
								icon="note"
								label="General Enquiry"
								placeholder="Leave a message"
								type="text"
								value={values.message}
								onChangeHandler={handleChange}
								onBlurHandler={handleBlur}
								touched={touched.message}
								error={errors.message}
							/>
							<Input
								idFor="email"
								icon="email"
								label="Email Address"
								placeholder="Email"
								type="email"
								value={values.email}
								onChangeHandler={handleChange}
								onBlurHandler={handleBlur}
								touched={touched.email}
								error={errors.email}
							/>
							<Input
								idFor="tel"
								icon="mobile"
								label="Mobile / Tel Number"
								placeholder="Contact Number"
								type="tel"
								value={values.tel}
								onChangeHandler={handleChange}
								onBlurHandler={handleBlur}
								touched={touched.tel}
								error={errors.tel}
							/>
							<input type="hidden" className="hidden" name="bot-field" />
							<input
								type="hidden"
								className="hidden"
								name="form-name"
								value="contact"
							/>
							<Button
								customClassName="FormBtn"
								type="submit"
								disabled={!(isValid && dirty) || isSubmitting}
								submitting={isSubmitting}
								label={isSubmitting ? 'Submitting Form...' : 'Submit Enquiry'}
							/>
						</FormContainer>
					)
				}}
			</Formik>

			{showModal && (
				<FormSentMsg>
					<div className="closeBtn" onClick={() => setModalState(false)}>
						<Icon icon="cross" fill="primary" />
					</div>
					<div className="msgIcon">
						<Icon icon="mailSent" />
					</div>
					<Title as="h4" clr="primary" marginBot="1em">
						Thank you for getting in touch!
					</Title>

					<Par className="msg" clr="707070" marginBot="0.4em">
						We appreciate you contacting Pumptec. One of our colleagues will get back
						in touch with you soon!
					</Par>

					<Par clr="707070">Have a great day!</Par>
				</FormSentMsg>
			)}
		</>
	)
}

export default Form
