import React from 'react'

// Styles
import { Banner } from './BannerCaption.styled'

const BannerCaption = () => (
	<Banner>
		<h2>LEADING THE WAY</h2>
		<p>Providing quality service.</p>
		<p>Supplying quality products.</p>
	</Banner>
)

export default BannerCaption
