import React from 'react'

// Elements
import { Icon } from '@components'

// Styles
import { StyledBtnLink } from './BtnLink.styled'

const BtnLink = ({ title, linkTo, icon = 'arrowLft', reverse }) => {
	if (reverse && icon === 'arrowLft') icon = 'arrowRgt'
	return (
		<StyledBtnLink to={linkTo} reverse={reverse}>
			{title}
			<Icon icon={icon} />
		</StyledBtnLink>
	)
}

export default BtnLink
