import styled, { css } from 'styled-components'
import { widthFrom } from '@theme'

export const Header = styled.header`
	width: 100%;
	max-height: 100%;
	display: block;
	height: auto;
	position: relative;


	section {
		width: 100%;
		top: 0;
		right: 0;
		z-index: 100;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: ${({ theme }) => theme.modify.rgba('primary', 1)};
		padding: 1.5em 0.5em;
		position: relative;

		.logo {
			min-width: 140px;
			min-width: 200px;
			margin-bottom: 3vh;
		}
	}

	${({ homeBanner }) =>
		homeBanner &&
		css`
			max-height: 55vh;

			section {
				background-color: ${({ theme }) => theme.modify.rgba('primary', 0)};
			}
		`}

	/* Breakpoint */
	${widthFrom.TABLET`
		section {
			flex-direction: row;
			padding: 2em calc(2em + 1vw);

			.logo {
				min-width: 140px;
				margin-bottom: 0;
			}
		}
  `}
`
