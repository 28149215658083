import styled from 'styled-components'
import { fluidFontSize } from '@theme'

export const Footer = styled.footer`
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: ${({ theme }) => theme.color.primary};
	padding: 2em 1em;
	overflow: hidden;

	nav {
		width: 100%;
		${fluidFontSize(10, 12)}
		margin-bottom: 2em;
		padding-bottom: 2em;
		border-bottom: 1px solid ${({ theme }) => theme.alpha.tertiary(0.5)};

		display: flex;
		justify-content: center;
		align-items: center;

		.navLink {
			font-weight: ${({ theme }) => theme.fontWeight.light};
			padding: 0 1em;
		}
	}

	small {
		margin-top: 1em;
		color: ${({ theme }) => theme.color.white};
	}
`
