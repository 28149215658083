import React from 'react'

// Components
import { NavLinks } from '@sections'
import { Logo } from '@components'

// Styles
import { Footer } from './siteFooter.styled'

export default function SiteFooter() {
	return (
		<Footer>
			{/* <nav>
				<NavLinks />
			</nav> */}
			<Logo logo="pumptec" width="100px" />
			<small>
				Copyright 1998 - {new Date().getFullYear()} &copy; Pumptect PTY LTD.
			</small>
		</Footer>
	)
}
