import styled from 'styled-components'
import { fluidFontSize, widthFrom } from '@theme'

export const HeaderNav = styled.nav`
	width: 100%;
	${fluidFontSize(14, 16)}

	display: flex;
	justify-content: center;
	align-items: center;

	/* Breakpoint */
	${widthFrom.TABLET`
		justify-content: flex-end;
  `}
`
