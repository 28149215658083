import styled, { css } from 'styled-components'
import { widthFrom } from '@theme'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;

	margin-top: ${({ marginTop }) => marginTop};
	margin-bottom: ${({ marginBot }) => marginBot};
	padding: ${({ padding }) => padding};

	display: grid;
	grid-template-columns: 1fr;
	grid-gap: ${({ gap }) => gap};
	align-items: start;

	${({ align }) =>
		align === 'center' &&
		css`
			justify-content: center;
		`}

	${({ align }) =>
		align === 'left' &&
		css`
			flex-direction: row-reverse;
			justify-content: flex-start;
		`}

	/* Breakpoint */
		${widthFrom.TABLET`
			flex-direction: row;
			
			grid-template-columns: ${({ repCount }) => `repeat(${repCount - 1}, 1fr)`};
  `}

	${widthFrom.SCREEN`
			flex-direction: row;
			
			grid-template-columns: ${({ repCount }) => `repeat(${repCount}, 1fr)`};
  `}
`
