import React from 'react'

// Elements
import { Title, Par } from '@theme'
import { Icon, Image } from '@components'

// Styles
import { CardEmployee } from './EmployeeCard.styled'

const EmployeeCard = ({ id, img, title, position, email }) => (
	<CardEmployee>
		<figure>
			<Image name={img} />
		</figure>
		<article className="employeeInfo">
			<Title as="h2" min={15} max={18} className="title">
				{title}
			</Title>
			<Par className="position" min={12} max={14}>
				{position}
			</Par>
			<div className="infoWrapper">
				<a
					className="group"
					href={`mailto:${email}`}
					target="_blank"
					id={`${id}_Link`}
				>
					<p className="info">{email}</p>
					<Icon icon="mail" />
				</a>
			</div>
		</article>
	</CardEmployee>
)

export default EmployeeCard
