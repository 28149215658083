import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

import { BackgroundFigure } from './BackgroundImage.styled'

const BackgroundImage = ({
	name,
	bgAlpha = 0.8,
	bgColor = 'primary',
	children,
}) => {
	const { allImageSharp } = useStaticQuery(graphql`
		query {
			allImageSharp {
				edges {
					node {
						fluid(maxWidth: 1200) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	`)

	return (
		<BackgroundFigure bgColor={bgColor} bgAlpha={bgAlpha}>
			<Img
				fluid={
					allImageSharp.edges.find((element) => {
						return element.node.fluid.src.split('/').pop() === name
					}).node.fluid
				}
			/>

			{children}
		</BackgroundFigure>
	)
}

BackgroundImage.propTypes = {
	classname: PropTypes.string,
	name: PropTypes.string,
}

export default BackgroundImage
