import React from 'react'
import styled, { css } from 'styled-components'

import { renderSvgElement } from '@utils'
import logoSet from './logoSet.json'

const StyledLogo = styled.figure`
	padding: 0;
	width: ${({ size, width }) => (size ? size : width)};
	height: ${({ size, height }) => (size ? size : height)};
	max-width: 230px;
	display: inline-flex;
	justify-content: center;
	align-items: center;

	.logoSvg {
		height: auto;
		width: 100%;
		border: none;
		outline: none;
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;

		${({ logoFill, theme }) => {
			switch (logoFill) {
				case 'pumptecLight':
					return css`
						#pathA {
							fill: ${theme.color.white};
						}
						#pathB {
							fill: ${theme.color.primary};
						}
						#pathC {
							fill: ${theme.color.secondary};
						}
					`
				default:
					return css`
						fill: ${theme.color.white};
					`
			}
		}}
	}
`

const Logo = ({
	logo,
	logoFill = 'pumptecLight',
	size,
	width,
	height = 'auto',
	padding,
}) => {
	let logoData = logoSet[logo]
	const logoElements = logoData.elements

	return (
		<StyledLogo
			size={size}
			width={width}
			height={height}
			padding={padding}
			logoFill={logoFill}
			className={`logo ${logo}_logo`}
		>
			<svg viewBox={logoData.viewbox} className="logoSvg">
				<title>{`${logoData}-logo`}</title>
				{logoElements.map((logoElement) => renderSvgElement(logoElement))}
			</svg>
		</StyledLogo>
	)
}

export default Logo
