import React from 'react'

export function useWindowSize() {
	const isClient = typeof window !== 'undefined'

	function getSize() {
		return {
			width: isClient ? window.innerWidth : undefined,
			height: isClient ? window.innerHeight : undefined,
		}
	}

	const [windowSize, setWindowSize] = React.useState(getSize)

	React.useLayoutEffect(() => {
		if (!isClient) {
			return undefined
		}

		function handleResize() {
			setWindowSize(getSize())
		}

		window.addEventListener('resize', handleResize)
		return () => window.removeEventListener('resize', handleResize)
	}, [windowSize])

	return windowSize
}
