import React from 'react'

// Elements
import { Title, Par } from '@theme'
import { Image } from '@components'

// Styles
import { CardEmployeeFactory } from './EmployeeCard.styled'

const EmployeeFactoryCard = ({ img, title, team }) => (
	<CardEmployeeFactory>
		<figure>
			<Image name={img} />
		</figure>
		<article className="employeeInfo">
			<Title as="h2" min={15} max={18} className="title">
				{title}
			</Title>
			{team.map((team, index) => (
				<Par key={index} className="position" min={13} max={15}>
					{team}
				</Par>
			))}
		</article>
	</CardEmployeeFactory>
)

export default EmployeeFactoryCard
