import React from 'react'
import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

const Image = ({ name }) => {
	const { allImageSharp } = useStaticQuery(graphql`
		query {
			allImageSharp {
				edges {
					node {
						fluid(maxWidth: 1200) {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
	`)

	return (
		<Img
			fluid={
				allImageSharp.edges.find((element) => {
					return element.node.fluid.src.split('/').pop() === name
				}).node.fluid
			}
		/>
	)
}

Image.defaultProps = {
	classname: `image`,
}

Image.propTypes = {
	classname: PropTypes.string,
	name: PropTypes.string.isRequired,
}

export default Image
