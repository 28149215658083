import styled, { css } from 'styled-components'
import { fluidFontSize } from '@theme'

export const CardEmployee = styled.div`
	width: 100%;
	height: 100%;
	padding: 0;
	color: ${({ theme }) => theme.color.primary};
	background: ${({ theme }) => theme.color.white};
	border: 1px solid ${({ theme }) => theme.color.ash};
	overflow: hidden;

	display: flex;
	flex-direction: row;

	display: grid;
	grid-template-columns: 120px 1fr;


	.gatsby-image-wrapper {
		width: 120px;
		height: 120px;
	}

	.employeeInfo{
		display: flex;
		flex-direction: column;

		display: grid;
		grid-template-rows: auto auto 1fr ;
		
		h2 {
			padding: 10px 10px 5px;
		}

		.position {
			padding: 0 10px 5px;
		}
	}

	.infoWrapper{
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: flex-end;


		.group {
			display: flex;
			align-items: center;
			flex-direction: row;
			height: 40px;
			width: 100%;
			position: relative;
			padding: 0;
			background: ${({ theme }) => theme.color.chalk};
			border-top: 1px solid ${({ theme }) => theme.color.ash};
			text-decoration: none;
			color: ${({ theme }) => theme.color.primary};

			.info{
				padding: 0 10px;
				${fluidFontSize(12, 14)}
				font-weight: ${({ theme }) => theme.fontWeight.regular};
			}

			.icon {
				height: 40px;
				width: 40px;
				padding: 0.5em;
				background: ${({ theme }) => theme.color.ash};
				position: absolute;
				top: 0;
				bottom: 0;
				right: 0;
			}

			&:hover{
				.icon {
					background: ${({ theme }) => theme.color.secondary};
				}
			}
		}
	}
`

export const CardEmployeeFactory = styled.div`
	width: 100%;
	height: 100%;
	padding: 0;
	color: ${({ theme }) => theme.color.primary};
	background: ${({ theme }) => theme.color.white};
	border: 1px solid ${({ theme }) => theme.color.ash};

	display: flex;
	flex-direction: column;

	figure {
		overflow: hidden;
		height: 160px;

		.gatsby-image-wrapper {
			width: 100%;
			height: auto;
		}
	}

	.employeeInfo {
		display: flex;
		flex-direction: column;
		padding: 10px 15px 20px;

		p {
			padding-bottom: 5px;
		}

		h2 {
			padding: 10px 0;
		}
	}
`
