import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

import { fluidFontSize } from '@theme'

export const StyledBtnLink = styled(Link)`
	display: flex;
	align-items: center;
	flex-direction: row;
	color: ${({ theme }) => theme.color.ele};
	background: ${({ theme }) => theme.color.chalk};
	height: 60px;
	min-width: 300px;
	text-decoration: none;
	text-transform: uppercase;
	white-space: nowrap;
	position: relative;
	padding: 0 calc(60px + 1em) 0 1.2em;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	${fluidFontSize(14, 16)}

	.icon {
		height: 60px;
		width: 60px;
		padding: 0.8em;
		background: ${({ theme }) => theme.color.ash};
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
	}

	${({ reverse }) =>
		reverse &&
		css`
			padding: 0 1.2em 0 calc(60px + 1em);

			.icon {
				left: 0;
			}
		`}
`
