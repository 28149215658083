import React from 'react'

// Elements
import { Title } from '@theme'

// Styles
import { CardCompany } from './CompanyCard.styled'

const CompanyCard = ({ company, intro, link }) => (
	<CardCompany to={link}>
		<figure className="logo">{company}</figure>
		<p className="info">{intro}</p>
		<div className="viewInfoLink">
			<Title as="h2" weight="semi" clr="white">
				View more
			</Title>
		</div>
	</CardCompany>
)

export default CompanyCard
