import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

import { widthFrom } from '@theme'
import { useWindowSize } from '../../hooks/windowResize'

import SubNav from './SubNav'

export const NavLinkLi = styled.li`
	width: auto;
	height: auto;
	list-style: none;

	&:hover {
		cursor: pointer;

		.subNav {
			opacity: 1;
			visibility: visible;
			top: ${({ subNavPosition }) => subNavPosition};

			display: grid;
			grid-template-columns: repeat(5, 1fr);
			grid-auto-rows: 50px;
			align-items: center;
			justify-content: center;
		}
	}
`

export const NavLink = styled(Link)`
	text-decoration: none;
	color: ${({ theme }) => theme.color.white};
	transition-duration: 0.5s;
	padding: 0 0 6px;
	margin: 0 0.5em;
	position: relative;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	white-space: nowrap;

	&::before,
	&::after {
		content: '';
		position: absolute;
		height: 1px;
		bottom: 0;
		right: 0;
	}

	&::before {
		background-color: ${({ theme }) => theme.color.white};
		width: calc(1.5em + 1vw);
	}

	&::after {
		background-color: ${({ theme }) => theme.color.secondary};
		width: 0;
		transition: all 400ms ease-in-out;
	}

	&:hover {
		&::after {
			width: calc(1.5em + 1vw);
		}
	}

	&.active {
		color: ${({ theme }) => theme.color.secondary};

		&::after {
			width: calc(1.5em + 1vw);
		}
	}

	/* Breakpoint */
	${widthFrom.TABLET`
		.navLink {
			margin: 0 1em;
		}
  `}
`

const NavLinks = () => {
	const windowResized = useWindowSize()

	const Tab = React.useRef(null)
	const [partiallyActive, setPartiallyActive] = React.useState(false)
	const [subNavPosition, setSubNavPosition] = React.useState(null)

	React.useEffect(() => {
		if (Tab) {
			const offSetTab = `${Tab.current.offsetTop + Tab.current.scrollHeight}px`
			setSubNavPosition(offSetTab)
		}
	}, [Tab, windowResized.width])

	const isActive = ({ isPartiallyCurrent }) => {
		setPartiallyActive(isPartiallyCurrent)
	}

	return (
		<>
			<NavLinkLi>
				<NavLink className="navLink" activeClassName="active" to="/about">
					About Us
				</NavLink>
			</NavLinkLi>
			<NavLinkLi ref={Tab} subNavPosition={subNavPosition}>
				<NavLink
					getProps={isActive}
					className={`navLink subMenu ${partiallyActive ? 'active' : ''}`}
					to="/products"
				>
					Suppliers
				</NavLink>
				<SubNav />
			</NavLinkLi>
			<NavLinkLi>
				<NavLink className="navLink" activeClassName="active" to="/services">
					Services
				</NavLink>
			</NavLinkLi>
			<NavLinkLi>
				<NavLink className="navLink" activeClassName="active" to="/contact">
					Contact
				</NavLink>
			</NavLinkLi>
		</>
	)
}

export default NavLinks
