import styled from 'styled-components'

export const StyledIcon = styled.figure`
	padding: 0;
	display: inline-flex;
	justify-content: center;
	align-items: center;

	.iconSvg {
		height: auto;
		width: 100%;
		border: none;
		outline: none;
		display: flex;
		justify-content: center;
		align-items: center;
		fill: ${({ theme }) => theme.color.white};
		pointer-events: none;
	}
`
