import styled from 'styled-components'

export const BackgroundFigure = styled.figure`
	display: block;
	width: 100%;
	height: auto;
	background-color: ${({ theme, bgColor, bgAlpha }) =>
		theme.modify.rgba(bgColor, bgAlpha)};
	position: relative;

	.gatsby-image-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute !important;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
	}
`
