import React from 'react'

// Utils
import { renderSvgElement } from '@utils'
import iconSet from './iconSet.json'

// Styles
import { StyledIcon } from './Icon.styled'

const Icon = ({ icon, fill, size, height, width, padding }) => {
	let iconData = iconSet[icon]
	const iconElements = iconData.elements

	const iconVb =
		icon === 'cross' || icon === 'mailSent' ? '0 0 512 512' : '0 0 50 50'

	return (
		<StyledIcon
			fill={fill}
			size={size}
			width={width}
			height={height}
			padding={padding}
			className={`icon ${icon}_icon`}
		>
			<svg viewBox={iconVb} className="iconSvg">
				<title>{`${icon}-icon`}</title>
				{iconElements.map((iconElement) => renderSvgElement(iconElement))}
			</svg>
		</StyledIcon>
	)
}

export default Icon
