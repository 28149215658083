import React from 'react'
import styled from 'styled-components'

// Elements
import { Icon } from '@components'

const FormButton = styled.button.attrs(() => ({
	type: 'submit',
}))`
	background-color: ${({ theme }) => theme.color.white};
	position: relative;
	padding: 0 60px 0 1em;
	height: 60px;
	text-align: left;
	text-transform: uppercase;
	font-weight: 400;
	color: ${({ theme }) => theme.color.grey};
	transition: all 0.3s ease;

	.icon {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 60px;
		height: 60px;
		background-color: ${({ theme, submitting }) =>
			submitting ? theme.color.green : theme.color.secondary};
		padding: 13px;
	}

	&[type='button'],
	&[type='submit'] {
		padding: 0 60px 0 1em;
	}

	&:disabled,
	&[disabled] {
		position: relative;
		padding: 0 60px 0 1em;
		height: 60px;
		text-align: left;
		text-transform: uppercase;
		font-weight: 400;
		color: ${({ theme }) => theme.color.grey};
		transition: all 0.3s ease;
		background-color: ${({ theme }) => theme.color.ash};

		.icon {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			width: 60px;
			height: 60px;
			padding: 13px;
			background-color: ${({ theme }) => theme.color.grey};
		}

		&:hover {
			.icon {
				background-color: ${({ theme }) => theme.color.grey};

				svg {
					transform: translate(0, 0);
				}

				&::before,
				&::after {
					content: '';
					display: none;
				}

				&::before {
					display: none;
				}

				&::after {
					display: none;
				}
			}
		}
	}

	&:hover {
		.icon {
			svg {
				transform: translate(2px, -2px);
			}

			&::before,
			&::after {
				content: '';
				position: absolute;
				background-color: white;
				border-radius: 50%;
			}

			&::before {
				bottom: 9px;
				left: 9px;
				width: 3px;
				height: 3px;
			}

			&::after {
				bottom: 15px;
				left: 15px;
				width: 5px;
				height: 5px;
			}
		}
	}
`

const Button = ({
	label,
	type = 'submit',
	disabled,
	submitting,
	customClassName,
}) => (
	<FormButton
		id="submit_btn"
		className={customClassName}
		type={type}
		disabled={disabled}
		submitting={submitting}
	>
		{label}
		<Icon icon="send" />
	</FormButton>
)

export default Button
