import styled, { css } from 'styled-components'
import { widthFrom, fluidFontSize } from '@theme'

export const Banner = styled.div`
	width: 100%;
	padding: 0 2em 1.5em;

	h2 {
		color: ${({ theme }) => theme.color.secondary};
		text-shadow: 0.05em 0.05em 0.1em ${({ theme }) =>
			theme.modify.rgba('primary', 0.4)};
	}

	p {
		color: ${({ theme }) => theme.color.white};
		margin-top: 0.1em;
		${fluidFontSize(16, 28)}
		text-shadow: 0.1em 0.1em 0.1em ${({ theme }) =>
			theme.modify.rgba('primary', 0.4)};
	}

	/* Breakpoint */
	${widthFrom.TABLET`
		padding: calc(1em + 4vh) calc(4em + 1vw) calc(4em + 4vh);
  `}
`
