import styled, { css } from 'styled-components'
import { fluidFontSize } from '@theme'

export const CardContact = styled.div`
	width: 100%;
	padding: 0;
	margin-bottom: ${({ marginBot }) => marginBot};
	color: ${({ theme }) => theme.color.primary};
	border: 1px solid ${({ theme }) => theme.color.ash};
	overflow: hidden;
	grid-column: ${({ gridColumn }) => gridColumn};
	grid-row: ${({ gridRow }) => gridRow};

	.label {
		padding: 0.7em 1.2em;
		${fluidFontSize(14, 18)}
		text-transform: uppercase;
		font-weight: ${({ theme }) => theme.fontWeight.semi};
	}

	.infoGroup {
		display: flex;
		align-items: center;
		flex-direction: row;
		background: ${({ theme }) => theme.color.chalk};
		min-height: 60px;
		width: 100%;
		text-decoration: none;
		white-space: nowrap;
		position: relative;
		padding: 1em calc(60px + 1em) 1em 1.2em;
		font-weight: ${({ theme }) => theme.fontWeight.regular};

		p,
		a {
			${fluidFontSize(14, 16)}
			color: ${({ theme }) => theme.color.primary};
			padding-bottom:  ${({ multiRow }) => (multiRow ? '0.1em' : 0)};
		}

		.link {
			text-decoration: none;
			cursor: pointer;
		}

		.icon {
			height: 100%;
			width: 60px;
			padding: 0.8em;
			background: ${({ theme }) => theme.color.ash};
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;

			svg {
				align-self: flex-start;
			}
		}

		&:hover{
			.link > .icon {
				background: ${({ theme }) => theme.color.secondary};
			}
		}
	}
`
