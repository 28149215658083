import React from 'react'

// Styles
import { Wrapper } from './BtnLinkWrapper.styled'

const BtnLinkWrapper = ({
	children,
	noHr,
	align = 'right',
	hrColor = 'ash',
	marginTop = 0,
	marginBot = 0,
	padding = 0,
	borderColor = 'white',
}) => {
	const hideHr = align === 'center' || noHr
	return (
		<Wrapper
			align={align}
			hrColor={hrColor}
			borderColor={borderColor}
			padding={padding}
			marginTop={marginTop}
			marginBot={marginBot}
		>
			{!hideHr && <div className="hr" />}
			{children}
		</Wrapper>
	)
}

export default BtnLinkWrapper
