import React from 'react'

// Styles
import { Wrapper } from './CardWrapper.styled'

const CardWrapper = ({
	children,
	align = 'right',
	marginTop = 0,
	marginBot = 0,
	padding = 0,
	gap = '1em',
	colRowCount,
}) => {
	const colCount = colRowCount || children.length || 3

	return (
		<Wrapper
			align={align}
			padding={padding}
			marginTop={marginTop}
			marginBot={marginBot}
			repCount={colCount}
			gap={gap}
		>
			{children}
		</Wrapper>
	)
}

export default CardWrapper
