import React from 'react'
import styled, { css } from 'styled-components'
import { Field } from 'formik'

// Elements
import { Icon } from '@components'
import { fluidFontSize } from '@theme'

const InputGroup = styled.div`
	background-color: ${({ theme }) => theme.color.white};
	position: relative;
	padding: 23px 60px 0 0;
	height: ${({ asTextarea }) => (asTextarea ? '137px' : '60px')};

	.label {
		position: absolute;
		top: 10px;
		left: 10px;
		right: 0;
		font-size: 11px;
		text-transform: uppercase;
		color: ${({ theme, error }) =>
			error ? theme.color.red : theme.color.primary};
		font-weight: 300;
	}

	.input {
		height: 100%;
		width: 100%;
		padding: ${({ asTextarea }) => (asTextarea ? '5px 10px' : '0 10px 5px')};
		border: none;
		outline: none;
		font-weight: 300;
		color: ${({ theme }) => theme.color.grey};

		&::placeholder {
			text-transform: uppercase;
			color: ${({ theme }) => theme.color.grey};
		}
	}

	.icon {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		width: 60px;
		height: 60px;
		background-color: ${({ theme, error }) =>
			error ? theme.color.red : theme.color.ash};
		padding: 13px;
	}

	${({ asTextarea }) =>
		asTextarea &&
		css`
			.icon {
				height: 100%;
				display: flex;
				justify-items: flex-start;
				align-items: flex-start;
			}
		`};
`

const ErrorMsg = styled.div`
	background-color: ${({ theme }) => theme.color.red};
	position: absolute;
	padding: 3px 1em;
	z-index: 1000;
	left: 0;
	right: 0;
	color: ${({ theme }) => theme.modify.lighten('red', 0.6)};
	${fluidFontSize(10, 12)}
`

const Input = ({
	idFor,
	label,
	icon = 'email',
	placeholder,
	type = 'text',
	value,
	onChangeHandler,
	onBlurHandler,
	error,
	touched,
	as,
	customClassName,
}) => (
	<InputGroup
		className={customClassName}
		error={error && touched}
		asTextarea={as === 'textarea'}
	>
		<label htmlFor={idFor} className="label">
			{label}
		</label>
		<Field
			as={as}
			id={idFor}
			placeholder={placeholder}
			type={type}
			value={value}
			onChange={onChangeHandler}
			onBlur={onBlurHandler}
			className="input"
		/>
		<Icon icon={icon} size="60px" />
		{error && touched && <ErrorMsg>{error}</ErrorMsg>}
	</InputGroup>
)

export default Input

//error && touched &&
