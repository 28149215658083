import React from 'react'
import { Link } from 'gatsby'

// Elements
import { HeaderNav, NavLinks } from '@sections'
import { Logo, Image, BackgroundImage, BannerCaption } from '@components'

// Styles
import { Header } from './SiteHeader.styled'

const getImageHandler = (path) => {
	var about = new RegExp('/about')
	var products = new RegExp('/products')
	var services = new RegExp('/services')
	var contact = new RegExp('/contact')

	if (about.test(path)) return `pumptec_about.jpg`
	if (products.test(path)) return `pumptec_products.jpg`
	if (services.test(path)) return `pumptec_services.jpg`
	if (contact.test(path)) return `pumptec_contact.jpg`
	return `pumptec_home.jpg`
}

const SiteHomeHeader = () => (
	<BackgroundImage name={`pumptec_home.jpg`} bgOpacity={0.4} bgColor="primary">
		<Header homeBanner>
			<section>
				<Link to="/">
					<Logo logo="pumptec" width="15vw" />
				</Link>
				<HeaderNav>
					<NavLinks />
				</HeaderNav>
			</section>
			<BannerCaption />
		</Header>
	</BackgroundImage>
)

const SitePageHeaders = (path, template) => (
	<Header>
		<section>
			<Link to="/">
				<Logo logo="pumptec" width="15vw" />
			</Link>
			<HeaderNav>
				<NavLinks />
			</HeaderNav>
		</section>
		{!template && <Image name={getImageHandler(path)} />}
	</Header>
)

const SiteHeader = ({ path, template }) => {
	const locationHome = path === '/'
	return locationHome ? SiteHomeHeader() : SitePageHeaders(path, template)
}

export default SiteHeader
