import React from 'react'

const renderSvgElement = ({ element, id, children, ...rest }) => {
	return React.createElement(
		element,
		{
			key: id,
			id,
			...rest,
		},

		[children && children.map(child => renderSvgElement(child))]
	)
}

export default renderSvgElement
