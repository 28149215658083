import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	margin-top: ${({ marginTop }) => marginTop};
	margin-bottom: ${({ marginBot }) => marginBot};
	padding: ${({ padding }) => padding};

	.hr {
		height: 1px;
		width: 100%;
		background-color: ${({ theme, hrColor }) => theme.color[hrColor]};
		border-right: 2em solid
			${({ theme, borderColor }) => theme.color[borderColor]};
	}

	${({ align }) =>
		align === 'center' &&
		css`
			justify-content: center;
		`}

	${({ align }) =>
		align === 'left' &&
		css`
			flex-direction: row-reverse;
			justify-content: flex-start;

			.hr {
				border-left: 2em solid
					${({ theme, borderColor }) => theme.color[borderColor]};
				border-right: none;
			}
		`}
`
