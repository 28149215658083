import React from 'react'

// Constants
import { THEMECONTENT } from '@constants'

// Elements
import { MaxWrapper } from '@components'
import { Title, Par } from '@theme'

// Styles
import { PageArticle } from './Artical.styled'

const Artical = ({
	title,
	titleAs = 'h3',
	subTitle,
	par,
	children,
	theme = 'white',
	titleClass,
}) => (
	<PageArticle bgColor={theme}>
		<MaxWrapper>
			<Title
				as={titleAs}
				weight="semi"
				clr={THEMECONTENT[theme].title}
				className={titleClass}
			>
				{title}
			</Title>
			{subTitle && (
				<Title
					as="h4"
					weight="light"
					marginTop="0.2em"
					clr={THEMECONTENT[theme].subTitle}
					max={20}
				>
					{subTitle}
				</Title>
			)}
			{par && (
				<Par marginTop="0.8em" clr={THEMECONTENT[theme].par}>
					{par}
				</Par>
			)}

			{children}
		</MaxWrapper>
	</PageArticle>
)

export default Artical
