export const THEMECONTENT = {
	chalk: {
		title: 'primary',
		subTitle: 'secondary',
		par: 'white',
	},
	white: {
		title: 'primary',
		subTitle: 'secondary',
		par: 'grey',
	},
	primary: {
		title: 'white',
		subTitle: 'secondary',
		par: 'grey',
	},
}
