import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

// Data
import data from '../../content/Suppliers-Content.json'
import NavLinks from './NavLinks'

export const SubNavUl = styled.div`
	width: 100vw;
	padding: 0;
	position: absolute;
	left: 0;
	list-style: none;
	clear: both;

	visibility: hidden;
	opacity: 0;
	display: none;

	.subNavLink {
		text-decoration: none;
		width: 100%;
		height: 50px;
		color: ${({ theme }) => theme.color.primary};
		border: 1px solid ${({ theme }) => theme.modify.rgba('primary', 0.6)};
		border-top: none;
		background-color: ${({ theme }) => theme.modify.rgba('white', 0.85)};
		display: flex;
		align-items: center;
		justify-content: center;
		transition: all 200ms ease-in-out;

		&:hover {
			background-color: ${({ theme }) => theme.modify.rgba('white', 1)};
		}

		&.active {
			color: ${({ theme }) => theme.color.secondary};
			background-color: ${({ theme }) => theme.modify.rgba('white', 1)};
		}
	}
`

const isActive = ({ isCurrent }) => {
	return isCurrent ? { className: 'subNavLink active' } : null
}

const SubNav = () => (
	<SubNavUl className="subNav">
		{data.suppliers.map((supplier) => (
			<Link
				key={supplier.name}
				to={`/products/${supplier.name}`}
				getProps={isActive}
				className="subNavLink"
			>
				{supplier.company}
			</Link>
		))}
	</SubNavUl>
)

export default SubNav
